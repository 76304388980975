const DemoCard = ({ num, img, caption }) => {
    return (
        <div className="demo-card">
            <div className="demo-caption" id={`caption-${num}`}>
                <div className="demo-step-number">
                    <p>{num}</p>
                </div>
                <p className="caption-text">{caption}</p>
            </div>
            <img src={img} alt={`Phone ${num}`} className="demo-phone"/>
        </div>
    )
}

export default DemoCard
