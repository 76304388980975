const api = {
    async post(url, phoneNum) {
        let response
        await fetch(url, {
            method: "POST",
            body: JSON.stringify({ phone: phoneNum })
        })
        .then(resp => resp.json())
        .then(data => {
            response = data
        })
        return response
    }
}

export default api