import slideOne from '../images/slide-1.png'
import slideTwo from '../images/slide-2.png'
import slideThree from '../images/slide-3.png'
import slideFive from '../images/slide-5.png'
import phoneOne from '../images/phone-01.png'
import phoneTwo from '../images/phone-02.png'
import phoneThree from '../images/phone-03.png'
import phoneFour from '../images/phone-04.png'
import ReactPlayer from 'react-player/vimeo'
import Carousel from "react-bootstrap/Carousel"
import DemoCard from "./DemoCard"
import { useState } from 'react'

const Content = () => {
    const [displayIndex, setDisplayIndex] = useState(0)

    const captions = [
        {
            id: 1,
            copy: <h1>You shouldn’t just “settle” in life, and that includes the mask you wear every night.</h1>
        },
        {
            id: 2,
            copy: <>
                    <h1>Had enough of that old mask?</h1>
                    <div className="line"/>
                    <div className="captions-container">
                        <h3>Masks that leak and cause skin irritation are just one reason to try a new solution.</h3>
                    </div>
                  </> 
        },
        {
            id: 3,
            copy: <>
                    <h1>There are more than 300 CPAP masks out there and more added every day.</h1>
                    <div className="line"/>
                    <div className="captions-container">
                        <h3>How can you possibly know which one is best for your specific needs?</h3>
                    </div>
                  </>
        },
        {
            id: 4,
            copy: <>
                    <h1>NEXTcpap uses artificial intelligence to pick, and fit, your perfect mask!</h1>
                    <div className="line"/>
                    <div className="captions-container">
                        <h3>Answer a few short questions about how you sleep, upload a selfie, and look forward to a better night sleep!</h3>
                    </div>
                  </>
        },
    ]

    const demoCardData = [
        {
            id: 1,
            img: phoneOne,
            caption: "NEXTcpap sends you a link via text"
        },
        {
            id: 2,
            img: phoneTwo,
            caption: "You tell us about your sleep"
        },
        {
            id: 3,
            img: phoneThree,
            caption: "You take a selfie"
        },
        {
            id: 4,
            img: phoneFour,
            caption: "We text you your ideal mask"
        }
    ]

    const demoCards = demoCardData.map((card) => {
        return <DemoCard 
                    key={card.id}
                    num={card.id}
                    img={card.img}
                    caption={card.caption}
                />
    })

    return (
        <>
            <div className="problems-section">
                <div className="block-container">
                    <div className="carousel-container">
                        <div className="carousel">
                            <Carousel onSelect={(selectedIndex, e) => setDisplayIndex(selectedIndex)}>
                                <Carousel.Item interval={7500}>
                                    <img src={slideOne} alt="First slide" />
                                </Carousel.Item>
                                <Carousel.Item interval={7500}>
                                    <img src={slideTwo} alt="Second slide" />
                                </Carousel.Item>
                                <Carousel.Item interval={7500}>
                                    <img src={slideThree} alt="Third slide" />
                                </Carousel.Item>
                                <Carousel.Item interval={7500}>
                                    <img src={slideFive} alt="Fifth slide" />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="carousel-bg"></div>
                    </div>
                    <div className="captions">
                        {captions[displayIndex].copy}
                    </div>
                </div>
            </div>
            <div className="solutions-section">
                <div className="block-container">
                    <div className="text-container">
                        <h1>Testimonials</h1>
                        <p>"NEXTcpap genuinely changed my life."</p>
                        <p>"I could never go back to the old process again."</p>
                        <p>"The amount of time and hassel I've saved since I started using NEXTcpap is immeasurable."</p>
                        <p>"An absolute game changer."</p>
                    </div>
                    <ReactPlayer url='https://vimeo.com/595929657' controls={true}/>
                </div>
            </div>
            <div className="demo-section">
                <div className="demo-bg"></div>
                <div className="demo-content">
                    <h1>4 Simple Steps to a Better Night's Sleep</h1>
                    <div className="line"/>
                    <div className="card-container">
                        { demoCards }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Content
