import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/reset.css"
import "./styles/style.css";
import { datadogRum } from '@datadog/browser-rum';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// track aapplication via DataDog
datadogRum.init({
    applicationId: 'f02ee4f7-bd8b-4a80-bd0d-953e808a17ef',
    clientToken: 'pubbd91fbfa5015e8dfac36537add3248be',
    site: 'datadoghq.com',
    service:'nextcpap',
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    trackInteractions: true
});