import { useState } from "react"
import Button from "./Button"
import api from "../functions/api"

const Form = ({ successState=false, errorState=null }) => {
    const [phone, setPhone] = useState("")
    const [formattedPhone, setFormattedPhone] = useState("")
    const [error, setError] = useState(errorState)
    const [success, setSuccess] = useState(successState)
    const [wasClicked, setWasClicked] = useState(false)
    
    let url
    
    if (process.env.REACT_APP_ENV === "prod") {
    	url = "https://api-prod.nextcpap.com/signup"
    } else if (process.env.REACT_APP_ENV === "test") {
    	url = "https://api-test.nextcpap.net/signup"
    } else if (process.env.REACT_APP_ENV === "dev") {
    	url = "https://api-dev.nextcpap.de/signup"
    } else {
        url = "https://api-dev.nextcpap.de/signup"
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (phone.length === 10) {
            setWasClicked(true)
            const response = await api.post(url, "1" + phone.toString())
            if (response.success) {
                setWasClicked(false)
                setSuccess(true)
            } else if (response.error === "InvalidRequest") {
                setWasClicked(false)
                setError("Invalid request.")
            } else if (response.error === "InvalidNumber") {
                setWasClicked(false)
                setError("Please enter a valid phone number.")
            } else if (response.error === "AlreadyRegistered") {
                setWasClicked(false)
                setError("Phone number already registered.")
            } else {
                setWasClicked(false)
                setError("Internal server error. Try again later.")
            }
        } else {
            setWasClicked(false)
            setError("Please enter a valid phone number.")
        }
    }

    const getDigitOnlyPhone = value => 
        value.toString().replace(/\D/g, '').substring(0, 10)

    const getFormattedPhone = value => {
        if (!value) return ''

        const phone = getDigitOnlyPhone(value)
        const areaCode = phone.substring(0, 3)
        const middle = phone.substring(3, 6)
        const last = phone.substring(6, 10)

        let formattedPhone = ''
        if (phone.length > 6) {
            formattedPhone = `(${areaCode}) ${middle} - ${last}`
        } else if (phone.length > 3) {
            formattedPhone = `(${areaCode}) ${middle}`
        } else if (phone.length > 0) {
            formattedPhone = `(${areaCode}`
        }
        return formattedPhone
    }
    
    return (
        <div className="form">
            { !success ? 
                <>
                    <h1>You are 3 minutes away from your perfect mask!</h1>
                    <h3>
                        Enter your cell number below. We will text you within
                        seconds for your fitting, and don't worry, we won't keep
                        your phone number or photo after we're done.
                    </h3>
                    { error ? <p>{error}</p> : null }
                    <form onSubmit={handleSubmit}>
                        <div>
                            <h3>+1</h3>
                            <input 
                                placeholder="Mobile phone number" 
                                value={formattedPhone}
                                maxLength="16" 
                                onChange={e => {
                                    setPhone(getDigitOnlyPhone(e.target.value))
                                    setFormattedPhone(getFormattedPhone(e.target.value))
                                }} 
                                required/>
                        </div>
                        <Button text="Submit" 
                                type="submit" 
                                label="submit" 
                                wasClicked={wasClicked}/>
                    </form>
                </>
                : 
                <>
                    <h1 className="success">Thank you for your interest.</h1> 
                    <h3 className="success-sub">We'll reach out soon with more details.</h3>
                </>
            }
        </div>
    )
}

export default Form
