import { ThreeDots } from "react-loading-icons"

const Button = ({ text, label, wasClicked=false, type=null }) => {
    return (
        <button className={label} 
                type={ type ? type : "button" }
                disabled={wasClicked} >
            { !wasClicked ? text : <ThreeDots fill="white" height=".5rem" /> }
        </button>
    )
}

export default Button
