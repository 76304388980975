import logo from '../images/logo_nextcpap.png'
// import "../styles/style.css"

const Header = () => {
    return (
        <div className="header">
            <img src={logo} alt="nextcpap"/>
            <p>...your perfect CPAP mask in 3 minutes</p>
        </div>
    )
}

export default Header
