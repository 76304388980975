import Form from "./Form"

const Signup = () => {
    return (
        <div className="signup-section">
            <Form />
        </div>
    )
}

export default Signup
